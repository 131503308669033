/* Tailwind Config */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --primary-color: 80 227 194;
        --primary-color-dark: 0 176 146;
        --primary-color-light: 138 255 245;
    }
}

/* Style overrides for various things - hackish and ugly */
body, html, #__next {
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
}

strong, b {
    font-weight: 700!important;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Hide ReCaptcha badge */
.grecaptcha-badge { visibility: hidden; }

/* <PageTransition */
.page-transition-enter {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}
.page-transition-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 300ms, transform 300ms;
}
.page-transition-exit {
  opacity: 1;
}
.page-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.page-row {
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
  margin: 0 auto;
}

.page-row-new {
    width: 100%;
    max-width: 1536px;
    padding: 0 15px;
    margin: 0 auto;
}

.page-row-new .MuiGrid-grid-lg-8 {
    flex-basis: 72%;
    max-width: 72%;
}

.page-row-new .MuiGrid-grid-lg-4 {
    flex-basis: 28%;
    max-width: 28%;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
}

.disabled * {
    pointer-events: none;
}

.clickable {
    cursor: pointer;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

*[onClick], a, .link {
  cursor: pointer;
}

*[onClick]:hover, a:hover, .link:hover {
  text-decoration: underline;
}

.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
}

.disabled * {
    pointer-events: none;
}

input {
    height: 19px;
}


/* Sticky-sidebar config */
.sidebar{
    will-change: min-height;
}

.sidebar__inner{
    transform: translate(0, 0); /* For browsers don't support translate3d. */
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
}

/* Horizontal Menu */
.menu-wrapper {
    padding-left: 16px;
}
.menu-item {
    user-select: none;
    cursor: pointer;
    border: none;
    outline: none;
}

.menu-item-wrapper {
    outline: none;
    vertical-align: bottom;
}

.menu-item-wrapper:last-of-type {
    padding-right: 2rem;
}

.scroll-menu-arrow--disabled {
    visibility: hidden;
}

.scroll-menu-arrow:first-of-type {
    cursor: pointer;
    position: absolute;
    left: 0;
    z-index: 1;
    background: white;
    padding-top: 21px;
    height: 80px;
    width: 2rem;
    overflow: hidden;
    background: rgba(250,250,250,1);
    background: -moz-linear-gradient(left, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 18%, rgba(250,250,250,0.6) 80%, rgba(250,250,250,0) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(250,250,250,1)), color-stop(18%, rgba(250,250,250,1)), color-stop(80%, rgba(250,250,250,0.6)), color-stop(100%, rgba(250,250,250,0)));
    background: -webkit-linear-gradient(left, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 18%, rgba(250,250,250,0.6) 80%, rgba(250,250,250,0) 100%);
    background: -o-linear-gradient(left, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 18%, rgba(250,250,250,0.6) 80%, rgba(250,250,250,0) 100%);
    background: -ms-linear-gradient(left, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 18%, rgba(250,250,250,0.6) 80%, rgba(250,250,250,0) 100%);
    background: linear-gradient(to right, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 18%, rgba(250,250,250,0.6) 80%, rgba(250,250,250,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#fafafa', GradientType=1 );
}
.scroll-menu-arrow:last-of-type {
    cursor: pointer;
    position: absolute;
    right: 0;
    z-index: 1;
    background: white;
    padding-top: 21px;
    height: 80px;
    width: 2rem;
    overflow: hidden;
    background: rgba(250,250,250,0);
    background: -moz-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(250,250,250,0.6) 20%, rgba(250,250,250,1) 82%, rgba(250,250,250,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(250,250,250,0)), color-stop(40%, rgba(250,250,250,0.6)), color-stop(82%, rgba(250,250,250,1)), color-stop(100%, rgba(250,250,250,1)));
    background: -webkit-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(250,250,250,0.6) 20%, rgba(250,250,250,1) 82%, rgba(250,250,250,1) 100%);
    background: -o-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(250,250,250,0.6) 20%, rgba(250,250,250,1) 82%, rgba(250,250,250,1) 100%);
    background: -ms-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(250,250,250,0.6) 20%, rgba(250,250,250,1) 82%, rgba(250,250,250,1) 100%);
    background: linear-gradient(to right, rgba(250,250,250,0) 0%, rgba(250,250,250,0.6) 20%, rgba(250,250,250,1) 82%, rgba(250,250,250,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#fafafa', GradientType=1 );
}
.big-number-input fieldset {
    border-width: 0px;
}

/* Make clicks pass-through */
#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #29d;

    position: absolute;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1.0;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
}

#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: #29d;
    border-left-color: #29d;
    border-radius: 50%;

    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

